import React, { useCallback } from 'react';
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  resetSidebars,
  setSetSectionsSidebarOpen,
  setSetDecisionsSidebarOpen,
  setAssignDecisionsSidebarOpen
} from "../../store/sidebar/actions";
import HamburgerDropdown from './HamburgerDropdown'
import { redirectToHomeCallback } from "../../helpers/decision_helpers";
import { successActions } from "../../helpers/common";
import { openModal } from "../../store/modals/actions";
import { resetSearchAndFilters } from "../../store/homepage/actions";
import OrgLogo from "../../tree_view/header/OrgLogo";
import ProgressMeter from "../../decision_set_view/ProgressMeter";
import AddDecisionView from "../../decision_set_search_view/header/AddDecisionView"
import { updateTemplateSetData } from "../../store/template_set/common_actions";

const TemplateFlowView = ({
                            set, homeTab, setTitle,
                            isManager = true, isTemplateSet= false,
                            current_org,
                            resetSet, removeSet,
                            resetSidebars, openModal, reloadRecords, resetSearchAndFilters, setSetSectionsSidebarOpen,
                            setSetDecisionsSidebarOpen, setAssignDecisionsSidebarOpen,
                            ...opts
                          }) => {
  const navigate = useNavigate();
  const processBackClick = useCallback(() => {
    successActions(true, [resetSet, resetSearchAndFilters, resetSidebars, reloadRecords]);
    redirectToHomeCallback(true, navigate);
  }, []);

  return <>
    <div className="d-flex align-items-center active"
         style={{maxWidth: 'calc(100% - 343px)'}}>
      <div className="logo-dropdown pointer d-none d-lg-flex me-2">
        <OrgLogo current_org={current_org} processBackClick={processBackClick} />
      </div>
      <h5>Decision flow template</h5>
    </div>
    <div className="navbar-nav ms-auto">
      <div className='d-lg-none'>
        <HamburgerDropdown {...{ ...opts, set, isTemplateSet, setTitle, homeTab, resetSet, removeSet, isManager, reloadRecords }} />
      </div>
    </div>
  </>
};
const SetHeader = ({ isAddPage= false, ...opts }) =>
  <header className="decision-tree-header">
    <nav className="navbar navbar-expand bd-navbar px-3">
      {
        (
          isAddPage && <AddDecisionView {...{ ...opts, isDecisionSet: false }} />
        ) || <TemplateFlowView {...opts} />
      }
    </nav>
    <ProgressMeter {...opts} />
  </header>

const mapStateToProps = ({ current_org, sidebar }) => ({ current_org, sidebar });

const mapDispatchToProps = (dispatch) => ({
  openModal: (data) => dispatch(openModal(data)),
  resetSidebars: () => dispatch(resetSidebars()),
  setSetSectionsSidebarOpen: () => dispatch(setSetSectionsSidebarOpen(true)),
  setSetDecisionsSidebarOpen: () => {
    dispatch(setSetDecisionsSidebarOpen(true));
    dispatch(updateTemplateSetData({ added_template_slugs: [] }));
  },
  resetSearchAndFilters: () => dispatch(resetSearchAndFilters()),
  setAssignDecisionsSidebarOpen: () => {
    dispatch(setAssignDecisionsSidebarOpen(true));
    dispatch(updateTemplateSetData({ added_template_slugs: [] }));
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(SetHeader);
