import React, {Fragment, useCallback, useEffect, useState} from 'react';
import { connect } from "react-redux";
import {
  isHomepageSectionLoaded,
  loadAdminTemplateSets,
  loadDashboardAdminTemplates,
  needToLoadHomepageSection
} from "../../store/homepage/actions";
import { Loader } from "../../common/Loader";
import { isBlank, isPresent } from "../../helpers/common";
import { addTemplateToSet } from "../../store/template_set/actions";
import { safeDescription } from "../../helpers/decision_helpers";
import { Link, useNavigate } from "react-router-dom";
import { isDSightTemplate, isTemplateDraft } from "../../helpers/template_helpers";
import SearchTemplateFilters from "./filters/SearchTemplateFilters";
import { loadOrgCategories } from "../../store/org_categories/actions";
import BubbleTooltip from '../../bubble_tooltip/BubbleTooltip'
import { homeSearchState } from "./SearchSection";
import { StartFromScratchBlock } from "./create_decision_tab/StartFromScratch";
import { closeModal, openModal } from "../../store/modals/actions";
import TemplateSetModal, { openTemplateSetModal, showTemplateSetModal } from '../modals/TemplateSetModal';
import AddToSetModal, {
  openAddToSetModalCallback,
  showAddToSetModal
} from '../../template_set_view/modals/AddToSetModal';
import { genNullResultsSection, TemplateCombinedList } from "./helpers/CombinedList";
import CreateTemplateModal, { openCreateTemplateModal, showCreateTemplateModal } from "../modals/CreateTemplateModal";
import { checkLoadingEffect, checkSearchChangeEffect } from "../../helpers/callbacks_helpers";
import { getSectionSlugFromParam } from "../../helpers/set_helpers";
import TemplatesFilters, {SelectAllButton} from "./helpers/TemplatesFilters";
import SearchRow, { resetSearchAction } from "./helpers/SearchRow";
import {onSelectTemplate, TemplateTileSelectButton} from "./dsight/tiles/TemplateTile";
import EnterCopiesNumberModal from "../../tree_view/modals/EnterCopiesNumberModal";
import {isDefaultCategoriesFilters, isDefaultFilters} from "../../helpers/filter_helpers";

const DraftTemplateTile = ({template}) =>
  <div className="position-relative col col-md-6 col-lg-4 col-xl-3 homepage_tile mb-3">
    <BubbleTooltip object_hash={template} template_set_name={template.template_set_name} />
    <div className="text-dark text-center">
      <div className="template-tile-height tile-content bg-white pt-3 px-2 pb-2">
        <h4 className="decision-title mb-3">
          <span className="text-gray">Draft: </span>
          {safeDescription(template)}
        </h4>
        <Link to={`/templates/${template.slug}/wizard`} target={`_self`}>
          <div className="btn btn-primary h-36 py-1 w-100">
            <span>Continue</span>
          </div>
        </Link>
      </div>
    </div>
  </div>

const TemplateButtons = ({ template }) =>
  <div className="template-tile-height tile-content bg-white pt-3 px-2 pb-2">
    <h4 className="decision-title mb-3">{safeDescription(template)}</h4>
    <Link to={`${isDSightTemplate(template) ? '/dsight' :  ''}/templates/${template.slug}`} target={`_self`}>
      <div className="btn btn-secondary btn-dsight align-center py-1 w-100">
        <span>Preview decision</span>
      </div>
    </Link>
  </div>

const SetTemplateButtons = ({ template, template_set, selectedTemplates, setSelectedTemplates }) => {
  const [showEnterCopiesNumberModal, setShowEnterCopiesNumberModal] = useState(false);
  const sectionSlug = getSectionSlugFromParam(document);
  const previewPath = `${isDSightTemplate(template) ? '/dsight' :  ''}/templates/${template.slug}/preview?template_set=${template_set.slug}${!!sectionSlug ? `&section_slug=${sectionSlug}` : ''}`;
  const selectedObj = isPresent(selectedTemplates) ? selectedTemplates[template.slug] : null
  const template_data = { template_set_slug: template_set.slug, section_slug: sectionSlug, template_slug: template.slug }
  const handleSelectDecision = ({selected = false, increment = 0 }) =>
    onSelectTemplate({selectedTemplates, template, setSelectedTemplates, selectedObj, template_data, selected, increment})

  return <Fragment>
      <div className="tile-content bg-white pt-3 px-2 pb-2">
      <h4 className="decision-title mb-3">{safeDescription(template)}</h4>
      <Link to={previewPath}>
        <div className="btn btn-secondary align-center h-36 py-1 w-100 mb-2">
          <span>Preview decision</span>
        </div>
      </Link>
      <TemplateTileSelectButton
        template={template}
        setShowEnterCopiesNumberModal={setShowEnterCopiesNumberModal}
        handleSelectDecision={handleSelectDecision}
        selectedObj={selectedObj}
      />
    </div>
    <div className="modals">
      <EnterCopiesNumberModal shown={showEnterCopiesNumberModal}
                              set_slug={template_set.slug}
                              section_slug={sectionSlug}
                              selectedObj={selectedObj}
                              selectedTemplates={selectedTemplates}
                              template={template}
                              setSelectedTemplates={setSelectedTemplates}
                              onClose={() => setShowEnterCopiesNumberModal(false)} />
    </div>
  </Fragment>
}

const CompleteTemplateTile = ({ template, isTemplateSet, selectedTemplateSet, ...opts }) =>
  <div className="position-relative col col-md-6 col-lg-4 col-xl-3 homepage_tile mb-3">
    <BubbleTooltip object_hash={template} template_set_name={template.template_set_name} {...{ ...opts, isAddTemplateSection: true }} />
    <div className="text-dark text-center">
      {
        isTemplateSet ?
          <SetTemplateButtons template={template} template_set={selectedTemplateSet} {...opts} /> :
          <TemplateButtons template={template} />
      }
    </div>
  </div>

const TemplateTile = ({template, isTemplateSet, ...opts}) =>
  !isTemplateSet && isTemplateDraft(template) ?
    <DraftTemplateTile {...{ ...opts, template }} /> :
    <CompleteTemplateTile {...{ ...opts, template, isTemplateSet }}/>

const TemplateSetTile = ({ template_set }) =>
  <div className="position-relative col col-md-6 col-lg-4 col-xl-3 homepage_tile mb-3">
    <div className="text-dark text-center">
      <div className="position-absolute top-0 ps-2">
        <i className="text-muted fas fa-list-check fa-lg" />
      </div>
      <div className="set-folder tile-content bg-white pt-3 px-2 pb-2">
        <h4 className="decision-title mb-3">{template_set.name}</h4>
        <Link to={`/template_sets/${template_set.slug}`} target={`_self`}>
          <div className="btn btn-secondary align-center h-36 py-1 w-100">
            <span>View decision flow template</span>
          </div>
        </Link>
      </div>
    </div>
  </div>

export const TemplatesTab = ({
                               isTemplateSet = false, template_set,
                               home, modal, org_categories, current_org,
                               loadDashboardAdminTemplates, loadOrgCategories,
                               openModal, closeModal, addTemplateToSet, loadAdminTemplateSets,
                               selectedTemplates, setSelectedTemplates = ()=>{}, isAddTemplate = false
                            }) => {
  const {
    searchQuery, setSearchQuery,
    loading, setLoading, resetSearch
  } = homeSearchState(home)
  const [submit, setSubmit] = useState(false)
  const usedFiltersBadges = home.d_sight_only || isPresent(home.category_options) || isPresent(home.template_users);
  const filtersUsed = isPresent(searchQuery) || usedFiltersBadges
  const filteredAdminTemplates = home.admin_templates.data.filter((t) => !isTemplateSet || !isDSightTemplate(t))
  const filteredAdminTemplateSets = isTemplateSet ? [] : home.admin_template_sets.data
  const showNullResults = filtersUsed && isBlank(filteredAdminTemplates) && isBlank(filteredAdminTemplateSets);
  const hideSelectAllButton = isBlank(filteredAdminTemplates) || !isAddTemplate || !filtersUsed;
  const sectionSlug = getSectionSlugFromParam(document);

  useEffect(() => {
    if (needToLoadHomepageSection(home, 'admin_templates')) loadDashboardAdminTemplates();
  }, [home.admin_templates.loaded])
  useEffect(() => {
    if (needToLoadHomepageSection(home, 'admin_template_sets')) loadAdminTemplateSets();
  }, [home.admin_template_sets.loaded]);
  const navigate = useNavigate();
  checkLoadingEffect(org_categories, loadOrgCategories)
  const loadRecords = (data = {}) => {
    loadDashboardAdminTemplates(data)
    if (!isTemplateSet) loadAdminTemplateSets(data)
  }

  checkSearchChangeEffect(loading, setLoading, searchQuery, loadRecords)

  const onAddTemplateToSet = useCallback((template) => {
    if(isBlank(template.template_set_slug)) {
      if (submit) return;

      setSubmit(true)
      addTemplateToSet(template.slug, template_set.slug, (success) => {
        if(success) {
          navigate(`/template_sets/${template_set.slug}?left_side_bar_open=set_decisions`)
        }
      }, sectionSlug)
    } else {
      openAddToSetModalCallback(openModal, template)
    }
  }, [template_set.slug, submit])

  const selectAllTemplates = () => {
    const newSelectedTemplates = filteredAdminTemplates.reduce((acc, template) => {
      if (!selectedTemplates[template.slug]) {
        const template_data = { template_set_slug: template_set.slug, section_slug: sectionSlug, template_slug: template.slug }
        acc[template.slug] = { number_of_copies: 1, ...template_data };
      }
      return acc;
    }, {});

    setSelectedTemplates({ ...selectedTemplates, ...newSelectedTemplates });
  };

  const renderRow = ({ type, ...t }) =>
    type === 'Template' ?
      <TemplateTile template={t} key={`homepage-template-tile-${t.slug}`} {...{ isTemplateSet, selectedTemplates, setSelectedTemplates }}
                    addTemplateToSet={onAddTemplateToSet}
                    selectedTemplateSet={template_set} /> :
      type === 'TemplateSet' ?
        <TemplateSetTile key={`homepage-template-set-tile-${t.slug}`}
                         template_set={t} /> :
        null

  return <>
    <div className={`${isTemplateSet ? 'd-none' : 'row'}`}>
      <div className="col">
        <h1>Templates</h1>
      </div>
    </div>
    <SearchRow {...{ rowStyles: 'mb-2', searchQuery, setSearchQuery, placeholder: 'Search templates' }}
               resetSearchAction={() => resetSearchAction(setSearchQuery, resetSearch)}>
      <SearchTemplateFilters {...{
        loadAction: loadRecords,
        isSetPage: isTemplateSet,
        templateUsersFilter: !isTemplateSet,
        allGroups: true
      }} />
    </SearchRow>
    <div className={`row ${usedFiltersBadges ? 'mb-2' : ''}`}>
      <div className={`${hideSelectAllButton ? 'col-12' : 'col-9'}`}>
        <TemplatesFilters {...{home, org_categories, loadDashboardTemplates: loadRecords, hideSelectAllButton}} />
      </div>
      <SelectAllButton {...{
        onClick: selectAllTemplates,
        hidden: hideSelectAllButton
      }} />
    </div>

    <div className={`row pt-2`}>
      <StartFromScratchBlock title={"Create a new template"}
                             onClick={() => openCreateTemplateModal({ openModal })}
                             addClass={`${isTemplateSet ? 'py-5' : 'template-tile-height'}`}
                             showNullResults={showNullResults} />
      {
        isTemplateSet || !current_org.allow_decision_sets ? null :
          <StartFromScratchBlock title={"Create a new decision flow"}
                                 iconClass="fas fa-list-check"
                                 onClick={() => openTemplateSetModal({ openModal })}
                                 addClass="template-tile-height" showNullResults={showNullResults}
          />
      }
      {
        isHomepageSectionLoaded(home, 'admin_templates') &&
          <TemplateCombinedList
            getTemplates={() => filteredAdminTemplates}
            getTemplateSets={() => !isTemplateSet && home.admin_template_sets.loaded ? home.admin_template_sets.data : []}
            renderNullResults={() => genNullResultsSection({ home, setSearchQuery, setLoading, action: loadRecords })}
            selectedTemplateSet={template_set}
            {...{ showNullResults, renderRow, isTemplateSet, filtersUsed }}
          />
      }
    </div>
    <div className="row loading mb-3 px-2" hidden={!home.admin_templates.loading}>
      <Loader />
    </div>
    <div className="modals">
      <TemplateSetModal shown={showTemplateSetModal(modal)} onClose={closeModal} />
      <CreateTemplateModal shown={showCreateTemplateModal(modal)} onClose={closeModal} isTemplateSet={isTemplateSet} />
      <AddToSetModal shown={showAddToSetModal(modal, template_set)} onClose={closeModal} />
    </div>
  </>;
}
const mapStateToProps = ({ home, modal, org_categories, template_set, current_org }) => ({ home, modal, org_categories, template_set, current_org });
const mapDispatchToProps = (dispatch) => ({
  loadDashboardAdminTemplates: (hash) => { dispatch(loadDashboardAdminTemplates(hash)) },
  loadAdminTemplateSets: (hash = {}) => { dispatch(loadAdminTemplateSets(hash)) },
  loadOrgCategories: () => { dispatch(loadOrgCategories()) },
  openModal: (data) => dispatch(openModal(data)),
  closeModal: (data) => dispatch(closeModal(data)),
  addTemplateToSet: (templateSlug, setSlug, callback, sectionSlug) => dispatch(addTemplateToSet(templateSlug, setSlug, callback, sectionSlug))
});
export default connect(mapStateToProps, mapDispatchToProps)(TemplatesTab);
