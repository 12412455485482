import React, {useState} from "react";
import {useNavigate} from "react-router";
import { HamburgerMenuIconBtn } from "../../common/dropdowns";
import BaseHamburgerBtn from "../../common/BaseHamburgerBtn";
import Button from "react-bootstrap/Button";
import {isBlank, isPresent, pluralize_text} from "../../helpers/common";
import {connect} from "react-redux";
import {
  addTemplatesToSet as addTemplatesToDecisionSet,
  addSetInvites,
  loadAddedDecisions,
  addDecisionsToSet, loadDecisionSet
} from "../../store/decision_set/actions";
import { updateDecisionSetData } from "../../store/decision_set/common_actions";
import { addTemplatesToSet as addTemplatesToTemplateSet } from "../../store/template_set/actions";
import {getSectionSlugFromParam} from "../../helpers/set_helpers";
import {changeDecisionInSetSharingSettings} from "../../helpers/alert_helpers";

const SET_DATA = {
  decision_set: { headerText: 'Add decisions', targetLocation: 'decision_sets', buttonText: 'Go to Decision flow', addSelectedDecisionBtnText: 'Add selected decisions' },
  template_set: { headerText: 'Add decisions', targetLocation: 'template_sets', buttonText: 'Go to Decision flow templates', addSelectedDecisionBtnText: 'Add selected decisions' }
}

export const generateDecisionDataFromTemplate = (template, section_slug, set_slug, isTemplateSet = false) => {
  return {
    ...isPresent(section_slug) && { section_slug: section_slug },
    decision_set_slug: set_slug,
    template_slug: template.slug,
    description: template.description,
    dsight: false,
    flow_enum: 'unstarted_tree',
    playbook: template.playbook,
    assigned_collaborator_email: template.assigned_collaborator_email,
    poll_notes: template.poll_notes,
    allow_collaborators_edit_tree: template.allow_collaborators_edit_tree,
    allow_collaborators_edit_collaborators: template.allow_collaborators_edit_collaborators,
    ai_instructions: template.ai_instructions,
    enable_ai_tree_completion: template.enable_ai_tree_completion
  }
}

const generateAssignedCollaborators = (selectedTemplates) =>
  Object.values(selectedTemplates).reduce((acc, template) => {
    if (isPresent(template.assigned_collaborator_email)) {
      acc.push(template.assigned_collaborator_email);
    }
    return acc;
  }, []);


const addTemplatesToDecisionSetAction = (addTemplatesToSet, selectedTemplates, set_slug, navigate,
                                         addSetInvites, loadAddedDecisions, setSubmit, updateDecisionSetData) => {
  setSubmit(true);
  updateDecisionSetData({ added_decision_slugs: [] })

  addTemplatesToSet({selected_templates: selectedTemplates}, (slugs) => {
    const assignedCollaborators = generateAssignedCollaborators(selectedTemplates);
    isPresent(assignedCollaborators) && addSetInvites(assignedCollaborators);
    loadAddedDecisions(slugs, (success) => {
      if (success) {
        setSubmit(false);
        navigate(`/decision_sets/${set_slug}?left_side_bar_open=set_decisions`);
      }
    });
  })
};

const addTemplatesToTemplateSetAction = (addTemplatesToSet, selectedTemplates, set_slug, navigate,
                                         setSubmit, updateTemplateSetData, sectionSlug) => {
  setSubmit(true);
  updateTemplateSetData({ added_template_slugs: [] })

  addTemplatesToSet({selected_templates: selectedTemplates}, (success) => {
    if (success) {
      setSubmit(false);
      navigate(`/template_sets/${set_slug}?left_side_bar_open=set_decisions`)
    }
  }, sectionSlug)
};
const addDecisionsToDecisionSetAction = (addDecisionsToSet, selectedDecisionSlugs, set, navigate,
                                         loadDecisionSet, loadAddedDecisions, setSubmit, submit, sectionSlug) => {
  const data = {
    decision_slugs: selectedDecisionSlugs,
    decision_set_slug: set.slug,
    section_slug: sectionSlug
  }

  const confirmCallback = (confirmed) => {
    if (!confirmed) return;

    setSubmit(true)
    addDecisionsToSet(data, () => {
      loadDecisionSet({ scope: 'decision_sets', slug: set.slug, loadVisibleDecisionsFlag: true })
      if (set.loaded) {
        setSubmit(false)
        setTimeout(() => navigate(`/decision_sets/${set.slug}?left_side_bar_open=set_decisions`), 200)
      }
    }, sectionSlug)
  }

  const object = pluralize_text(selectedDecisionSlugs.length, 'decision');
  changeDecisionInSetSharingSettings(`Added ${object} will be visible to anyone who can see "${set.name}"`, confirmCallback, object)
};

const AddDecisionView = ({
                           selectedTemplates, isDecisionSet = true, set,
                           addTemplatesToDecisionSet, addSetInvites, loadAddedDecisions,
                           updateDecisionSetData, addTemplatesToTemplateSet, selectedDecisionSlugs,
                           isSetSearchPage = false, addDecisionsToSet, loadDecisionSet
                        }) => {
  const navigate = useNavigate();
  const [submit, setSubmit] = useState(false);
  const sectionSlug = getSectionSlugFromParam(document);
  const { targetLocation, headerText, buttonText, addSelectedDecisionBtnText } = SET_DATA[isDecisionSet ? 'decision_set' : 'template_set'];

  const backAction = () => navigate(`/${targetLocation}/${set.slug}`);

  const onAddTemplatesToDecisionSet = () => addTemplatesToDecisionSetAction(
    addTemplatesToDecisionSet, selectedTemplates, set.slug, navigate, addSetInvites,
    loadAddedDecisions, setSubmit, updateDecisionSetData
  );

  const onAddTemplatesToTemplateSet = () => addTemplatesToTemplateSetAction(
    addTemplatesToTemplateSet, selectedTemplates, set.slug, navigate,
    setSubmit, updateDecisionSetData, sectionSlug
  );
  const onAddDecisionsToDecisionSet = () => addDecisionsToDecisionSetAction(
    addDecisionsToSet, selectedDecisionSlugs, set, navigate, loadDecisionSet,
    loadAddedDecisions, setSubmit, submit, sectionSlug
  );

  const addAction = isSetSearchPage
    ? onAddDecisionsToDecisionSet
    : isDecisionSet
        ? onAddTemplatesToDecisionSet
        : onAddTemplatesToTemplateSet;
  const disableAddBtn = (isBlank(selectedTemplates) && isBlank(selectedDecisionSlugs)) || submit;

  return <>
    <div className="d-flex align-items-center active w-100">
      <div className="btn btn-secondary btn-lg-round d-none d-lg-flex justify-content-around me-2" onClick={backAction}>
        <i className="fas fa-lg fa-arrow-left"/>
      </div>
      <h5>{headerText}</h5>
      <div className='ms-auto d-none d-lg-flex'>
        <Button variant='primary' disabled={disableAddBtn}
                onClick={addAction}>{addSelectedDecisionBtnText}</Button>
      </div>
      <div className='d-lg-none ms-auto'>
        <HamburgerMenuIconBtn>
          <BaseHamburgerBtn disabled={disableAddBtn} onSelect={addAction} title={addSelectedDecisionBtnText} />
          <BaseHamburgerBtn onSelect={backAction} title={buttonText} />
        </HamburgerMenuIconBtn>
      </div>
    </div>
  </>
};
const mapDispatchToProps = (dispatch) => ({
  addTemplatesToDecisionSet: (data = {}, callback) => dispatch(addTemplatesToDecisionSet(data, callback)),
  addSetInvites: (invites, callback) => { dispatch(addSetInvites(invites, callback)) },
  loadAddedDecisions: (slugs, callback) => dispatch(loadAddedDecisions(slugs, callback)),
  updateDecisionSetData: (data) => { dispatch(updateDecisionSetData(data)) },
  addTemplatesToTemplateSet: (data = {}, callback, sectionSlug) => dispatch(addTemplatesToTemplateSet(data, callback, sectionSlug)),
  addDecisionsToSet: (data, callback, sectionSlug) => dispatch(addDecisionsToSet(data, callback, sectionSlug)),
  loadDecisionSet: () => dispatch(loadDecisionSet())
});
export default connect(null, mapDispatchToProps)(AddDecisionView);