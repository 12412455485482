import React from "react";
import { connect } from "react-redux";
import { isBlank, isPresent } from "../helpers/common";
import { StartFromScratchBlock } from "../homepage/tabs/create_decision_tab/StartFromScratch";
import { Loader } from "../common/Loader";
import DecisionTile from "../homepage/tabs/tiles/DecisionTile";
import UnstartedTemplateTile from "./tiles/UnstartedTemplateTile";
import { isStoreLoading } from "../helpers/callbacks_helpers";
import { closeModal } from "../store/modals/actions";
import DecisionSet, { calcSequenceIndexByIndex, calcSequenceNumber } from "../models/decision_set";
import DraftTile from "../homepage/tabs/create_decision_tab/DraftTile";
import { useNavigate } from "react-router-dom";
import { isDraftDecision, isUnstartedDecision } from "../models/decision_state";

const Tile = ({ decision, setObj, decision_set, startIndex = 0, index = 0 }) => {
  const hasAccess = decision_set.accessible_decisions?.some(slug => slug === decision.slug)
  const sequence_number = calcSequenceNumber(decision_set, startIndex, index)

  const opts = { decision: { ...decision, sequence_number, set_users: decision_set?.users }, hasAccess }
  if (isUnstartedDecision(decision)) return <UnstartedTemplateTile {...opts} />;
  if (isDraftDecision(decision))
    return <DraftTile {...{ ...opts, decision_set, isDecisionSet: true, couldStart: setObj.isSetMember }} />;

  return <DecisionTile {...{ ...opts }} section={'decision_sets'} />;
}

const DecisionsList = ({ decisions, setObj, decision_set, startIndex = 0 }) =>
  decisions.map((decision, index) =>
    <Tile {...{
      decision_set, decision,
      startIndex, index, setObj,
      key: `decision-sets-decision-tile-${decision.slug}`
    }} />
  )

const SetSectionList = ({ section, setObj, ...opts }) =>
  <>
    <div className="row">
      <div className="col">
        <h2>{section.name}</h2>
      </div>
    </div>
    <DecisionsList {...{ ...opts, setObj, decisions: setObj.decisionsInSection(section) }} />
  </>

const DecisionSetDecisionsList = ({ decision_set, setObj }) => {
  if(isStoreLoading(decision_set) && isBlank(decision_set.decisions)) return <Loader/>;

  if(isBlank(decision_set.sections)) {
    return <DecisionsList {...{ decision_set, setObj, decisions: setObj.decisions }} />
  }

  const sections = decision_set.sections;

  return <>
    <DecisionsList {...{ decision_set, setObj, decisions: setObj.decisionsWithoutSection }} />
    {
      sections.map((section, index) =>
        isPresent(section.decisions) ?
          <SetSectionList key={`decision-sets-section-${section.slug}-tiles`}
                          {...{
                            section, setObj, decision_set,
                            startIndex: calcSequenceIndexByIndex(sections, index, setObj.decisionsWithoutSection)
                          }} /> :
          null
      )
    }
  </>
}

const DecisionsSection = ({ decision_set, current_user }) => {
  const navigate = useNavigate();

  const setObj = new DecisionSet(decision_set, current_user)
  return <div className="row">
    <h1>{decision_set.name}</h1>
    <DecisionSetDecisionsList {...{ decision_set, setObj }} />
    {
      decision_set.loaded && isBlank(decision_set.decisions) && setObj.isSetMember &&
        <StartFromScratchBlock title={"Add decisions"}
                               addClass="py-5"
                               onClick={() => navigate(`/decision_sets/${decision_set.slug}/add_decision`)}
                               showNullResults={false} />
    }
  </div>
}
const mapStateToProps = ({ decision_set, current_user }) => ({ decision_set, current_user });
export default connect(mapStateToProps, { closeModal })(DecisionsSection);
