import React, {useEffect, useState} from 'react';
import Modal from "react-bootstrap/Modal";
import CloseIcon from "../../common/CloseIcon";
import { ModalDoneFooter } from "../../common/modals";
import Form from "react-bootstrap/Form";
import {generateDecisionDataFromTemplate} from "../../decision_set_search_view/header/AddDecisionView";
import {isEnter} from "../../helpers/keys_helpers";

export const MIN_COPIES = 1
export const MAX_COPIES = 99

const EnterCopiesNumberModal = ({
                                  shown, onClose, setSelectedTemplates, selectedObj,
                                  selectedTemplates, template, set_slug, section_slug
                                }) => {
  const [copies, setCopies] = useState(selectedObj?.number_of_copies || 1)
  const submitData = () => {
    if (copies === selectedObj.number_of_copies) {
      onClose()
      return
    }
    if (copies <= 0) {
      const newSelectedTemplates = {...selectedTemplates}
      delete newSelectedTemplates[template.slug]
      setSelectedTemplates(newSelectedTemplates)
      onClose()
      return
    }
    const template_data = generateDecisionDataFromTemplate(template, section_slug, set_slug)
    const restrictedCopies = Math.min(MAX_COPIES, Math.max(MIN_COPIES, Number(copies)))
    setSelectedTemplates({...selectedTemplates, [template.slug]: {number_of_copies: restrictedCopies, ...template_data}})
    onClose()
  }

  const onKeyDown = (e) => {
    if(isEnter(e.keyCode)) {
      submitData()
    }
  }

  useEffect(() => {
    setCopies(selectedObj?.number_of_copies || 1)
  }, [shown])

  return <Modal enforceFocus={false} size="md" backdrop="static" show={shown} onHide={onClose}>
    <Modal.Body>
      <div>
        <CloseIcon onClose={onClose} classParams="float-end ms-2"/>
        <h2>Add to decision flow</h2>
      </div>
      <div className="mt-2">
        <Form.Label className='h3'>Number of copies</Form.Label>
        <Form.Control
          as='input'
          type='number'
          autoFocus
          min={MIN_COPIES}
          max={MAX_COPIES}
          placeholder={'Enter number of copies'}
          value={Number(copies)}
          onKeyDown={onKeyDown}
          onChange={(e) => setCopies(Number(e.target.value))}
        />
      </div>
    </Modal.Body>
    <ModalDoneFooter onClose={submitData}/>
  </Modal>
}

export default EnterCopiesNumberModal;
