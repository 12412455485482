import React from 'react';
import { isDefaultTemplateFilters } from "../../../helpers/filter_helpers";
import SelectedFiltersPills from "./SelectedFiltersPills";
import Button from "react-bootstrap/Button";

export const SelectAllButton = ({onClick, hidden, classNames= "col-3 text-end ms-auto"}) =>
  <div className={classNames}>
    <Button bsPrefix="btn btn-primary h-36 text-white float-end py-1 px-3"
            onClick={onClick}
            hidden={hidden}>
      Select all
    </Button>
  </div>


export default ({home, org_categories, loadDashboardTemplates, hideSelectAllButton = true}) =>
  isDefaultTemplateFilters(home) ? null :
    <>
      {
        hideSelectAllButton
          ? <SelectedFiltersPills {...{home, org_categories, loadDashboardTemplates}} />
          : <div className={`row`}>
              <SelectedFiltersPills {...{home, org_categories, loadDashboardTemplates}} />
            </div>
      }
    </>

 