import React, {useEffect, useState} from "react";
import BubbleTooltip from '../../../../bubble_tooltip/BubbleTooltip';
import { isMobile } from "react-device-detect";
import { isDecisionLoading, tileSectionsFlags } from "../../tiles/helpers";
import DecisionTileContent from "../../tiles/DecisionTileContent";
import { Loader } from "../../../../common/Loader";

const DSightDecisionTile = ({
                              decision, current_user, section = '',
                              children, selectedDecisionSlugs = [],
                              isDecisionSet = false, isApprovalNeeded = false
                            }) => {
  const [showChildren, setShowChildren] = useState(!!isMobile);
  const selected = selectedDecisionSlugs.includes(decision.slug)

  useEffect(() => {
    selected && setShowChildren(true)
  }, [selected]);

  if(isDecisionLoading(decision)) {
    return <div className={`position-relative col col-md-6 col-lg-4 col-xl-3 homepage_tile mb-3 load-decision-slug-${decision.slug}`}>
      <Loader />
    </div>
  }

  const {
    isSearch, isDSightHistory, isInDecisionSet
  } = tileSectionsFlags(section)

  return (
    <div className={`position-relative col col-md-6 col-lg-4 col-xl-3 homepage_tile mb-3 load-decision-slug-${decision.slug}`}
         onMouseEnter={e => {
           setShowChildren(true);
         }}
         onMouseLeave={e => {
           selected || isMobile ? {} : setShowChildren(false)
         }}
    >
      <BubbleTooltip {...{ object_hash: decision, decision_set_name: decision.decision_set_name, isDecisionSet }} />
      <DecisionTileContent {...{ decision, isSearch, isDSightHistory, isInDecisionSet, isApprovalNeeded, current_user, hideCollaborators: children && showChildren, isDecisionSet }} />
      {
        children && showChildren &&
        <div className="position-absolute bottom-0 ps-2 pe-3" style={selected ? {width: '308px'} : {}}>
          {children}
        </div>
      }
    </div>
  )
}
export default DSightDecisionTile;
