import React from 'react';
import { connect } from "react-redux";
import { isPresent} from "../../../helpers/common";
import {Link} from "react-router-dom";
import NullSearchResults from "../helpers/NullSearchResults";
import DecisionTile from "../tiles/DecisionTile";
import DecisionSetTile from "../tiles/DecisionSetTile";

const DecisionTileSelectButton = ({decision, selectedDecisionSlugs, setSelectedDecisionSlugs, isListLine}) => {
  const selected = selectedDecisionSlugs.includes(decision.slug)
  const onChangeSelected = () => {
    if (selected) {
      setSelectedDecisionSlugs(selectedDecisionSlugs.filter(slug => slug !== decision.slug))
    } else {
      setSelectedDecisionSlugs([...selectedDecisionSlugs, decision.slug])
    }
  }

  if (selected) {
    return <div onClick={onChangeSelected} key={`check-${decision.slug}`}
                className={`btn btn-success btn-dsight d-inline-flex justify-content-center text-white align-items-center p-1 w-100 ${isListLine ? '' : 'mb-2'} me-2 pointer`}>
      <i className="fas fa-check fa-lg me-2"/>
      <span>Selected</span>
    </div>
  }

  return <div onClick={onChangeSelected} key={`add-${decision.slug}`}
              className={`btn btn-primary btn-dsight d-inline-flex justify-content-center align-items-center py-1 w-100 ${isListLine ? '' : 'mb-2'} pointer`}>
    <i className="fas fa-plus fa-lg me-2"/>
    <span>Add to decision flow</span>
  </div>
}

const ViewDecision = ({decision, isListLine}) =>
  <Link to={`/decisions/${decision.slug}/tree_viewer`} target="_blank">
    <div
      className={`btn btn-secondary btn-dsight d-inline-flex justify-content-center align-items-center py-1 w-100 ${isListLine ? '' : 'mb-2'}`}>
      <span className={'text-nowrap'}>View decision</span>
    </div>
  </Link>

const DecisionInSetActions = ({decision, selectedDecisionSlugs, setSelectedDecisionSlugs, isListLine}) => {
  if (isListLine) return <div className='d-flex w-100'>
    <div className='me-2'>
      <ViewDecision {...{decision, isListLine}} />
    </div>
    <div style={{minWidth: '200px'}}>
      <DecisionTileSelectButton {...{decision, selectedDecisionSlugs, setSelectedDecisionSlugs, isListLine}} />
    </div>
  </div>

  return <div>
    <ViewDecision {...{decision, isListLine}} />
    <DecisionTileSelectButton {...{decision, selectedDecisionSlugs, setSelectedDecisionSlugs}} />
  </div>
}

const List = ({
                home, decisions,
                showNullResults, resetAllFilters,
                isDecisionSet = true,
                isListLine = false,
                decision_set_slug, isSearchTab = false,
                searchView,
                selectedDecisionSlugs, setSelectedDecisionSlugs
              }) => {
  if (showNullResults) return <NullSearchResults {...{home, resetAllFilters, searchView}} />;

  return decisions.map(({type = 'Decision', ...data }) =>
    type === 'Decision' ?
      <DecisionTile {...{ decision: data, isDecisionSet, section: 'search', isListLine, selectedDecisionSlugs, isSearchTab }}
                    key={`search-tab-decision-tile-${data.slug}`}>
        {
          isPresent(decision_set_slug) && <DecisionInSetActions {...{ decision: data, selectedDecisionSlugs, setSelectedDecisionSlugs, isListLine }} />
        }
      </DecisionTile> :
      <DecisionSetTile {...{ decision_set: data, isListLine, section: 'search' }}
                       key={`search-tab-decision-set-tile-${data.slug}`} />
  )
}
const mapStateToProps = ({ home }) => ({ home });
export default connect(mapStateToProps, {})(List);
