import React, {Fragment, useState} from 'react';
import { connect } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { safeDescription } from "../../../../helpers/decision_helpers";
import { isDSightTemplate } from "../../../../helpers/template_helpers";
import { isPresent, previewQueryParams } from "../../../../helpers/common";
import BubbleTooltip from '../../../../bubble_tooltip/BubbleTooltip'
import { openModal } from "../../../../store/modals/actions";
import { openStartDecisionModal } from "../../../modals/StartDecisionModal";
import { setSetDecisionsSidebarOpen } from "../../../../store/sidebar/actions";
import { startNewDecision } from "../../../../store/homepage/actions";
import { resetDecisionSetData } from "../../../../store/decision_set/common_actions";
import { getSectionSlugFromParam } from "../../../../helpers/set_helpers";
import DSightTemplateTile from "./DSightTemplateTile";
import {addSetInvites, loadAddedDecision} from "../../../../store/decision_set/actions";
import { getScenarioIdFromParam } from "../../../../forecast_simulator_scenario/helpers/helpers";
import EnterCopiesNumberModal, {MAX_COPIES, MIN_COPIES} from "../../../../tree_view/modals/EnterCopiesNumberModal";
import {generateDecisionDataFromTemplate} from "../../../../decision_set_search_view/header/AddDecisionView";

const AddButtonWrapper = ({children, onClick}) =>
  <div className={`btn btn-primary align-center h-36 py-1 w-100 pointer`} onClick={onClick}>
    {children}
  </div>


export const TemplateTileSelectButton = ({handleSelectDecision, selectedObj, template, setShowEnterCopiesNumberModal,
                                           showEnterCopiesNumberModal, set_slug, section_slug, selectedTemplates, setSelectedTemplates}) => {
  if (isPresent(selectedObj)) {
    return <Fragment>
      <div className='d-flex' key={`check-${selectedObj.number_of_copies}`}>
        <div
          className="btn btn-secondary btn-sm btn-sm-round d-inline-flex justify-content-center align-items-center mb-2"
          onClick={() => handleSelectDecision({increment: -1})}>
          <i className="fas fa-minus h4 m-0"/>
        </div>
        <div className={`btn btn-success align-center h-36 py-1 w-100 pointer text-white mx-1`}
             onClick={() => setShowEnterCopiesNumberModal(true)}>
          <i className={`fas fa-check fa-lg me-1`}/>
          <span>{selectedObj.number_of_copies} selected</span>
        </div>
        <div
          className="btn btn-primary btn-sm btn-sm-round d-inline-flex justify-content-center align-items-center mb-2"
          onClick={() => handleSelectDecision({increment: 1})}>
          <i className="fas fa-plus h4 m-0"/>
        </div>
      </div>
      <div className="modals">
        <EnterCopiesNumberModal shown={showEnterCopiesNumberModal}
                                set_slug={set_slug}
                                section_slug={section_slug}
                                selectedObj={selectedObj}
                                selectedTemplates={selectedTemplates}
                                template={template}
                                setSelectedTemplates={setSelectedTemplates}
                                onClose={() => setShowEnterCopiesNumberModal(false)}/>
      </div>
    </Fragment>
  }

  return <AddButtonWrapper onClick={() => handleSelectDecision({selected: true})} key={`add-${template.slug}`}>
    <i className="fas fa-plus fa-lg me-2"/>
    <span>Add to decision flow</span>
  </AddButtonWrapper>
}

export const onSelectTemplate = ({
                                   selectedTemplates, template, setSelectedTemplates,
                                   selectedObj, template_data, selected = false, increment = 0
                                 }) => {
  if (selected) {
    setSelectedTemplates({...selectedTemplates, [template.slug]: {number_of_copies: 1, ...template_data}})
  } else {
    const newCount = selectedObj.number_of_copies + increment
    if (newCount <= 0) {
      const newSelectedTemplates = {...selectedTemplates}
      delete newSelectedTemplates[template.slug]
      setSelectedTemplates(newSelectedTemplates)
      return
    }
    const restrictedCopies = Math.min(MAX_COPIES, Math.max(MIN_COPIES, Number(newCount)))
    setSelectedTemplates({...selectedTemplates, [template.slug]: {number_of_copies: restrictedCopies, ...template_data}})
  }
}

export const TemplateTile = ({
                               template,
                               openModal,
                               resetDecisionSetData,
                               startNewDecision,
                               isDecisionSet = false,
                               addSetInvites,
                               loadAddedDecision,
                               selectedTemplates,
                               setSelectedTemplates,
                               backPath = null }) => {
  const [submit, setSubmit] = useState(false)
  const [showEnterCopiesNumberModal, setShowEnterCopiesNumberModal] = useState(false)
  const selectedObj = isPresent(selectedTemplates) ? selectedTemplates[template.slug] : null

  if (isDSightTemplate(template)) { return <DSightTemplateTile template={template} /> }

  const navigate = useNavigate();
  const { id } = useParams();

  const handleOnClick = () => {
    if(isDecisionSet) {
      if (submit) return false;

      setSubmit(true)
      const section_slug = getSectionSlugFromParam(document);
      const data = generateDecisionDataFromTemplate(template, section_slug, id)
      startNewDecision(data, (slug) => {
        isPresent(template.assigned_collaborator_email) && addSetInvites([template.assigned_collaborator_email])
        loadAddedDecision(slug);
        setSubmit(false)
        navigate(`/decision_sets/${id}?left_side_bar_open=set_decisions`)
      })
    } else {
      openStartDecisionModal({ openModal, template })
    }
  };

  const section_slug = getSectionSlugFromParam(document);
  const forecast_scenario_id = getScenarioIdFromParam(document);
  const queryParams = {
    ...(isPresent(backPath) && backPath),
    ...(isPresent(section_slug) && { section_slug }),
    ...(isPresent(forecast_scenario_id) && { forecast_scenario_id }),
  }
  const template_data = generateDecisionDataFromTemplate(template, section_slug, id)
  const handleSelectDecision = ({selected = false, increment = 0 }) =>
    onSelectTemplate({selectedTemplates, template, setSelectedTemplates, selectedObj, template_data, selected, increment})

  return <div className="position-relative col col-md-6 col-lg-4 col-xl-3 homepage_tile mb-3">
    <BubbleTooltip object_hash={template} template_set_name={template.template_set_name}
                   isDecisionSet={isDecisionSet}/>
    <div className="text-dark text-center">
      <div className="tile-content bg-white pt-3 px-2 pb-2">
        <h4 className="decision-title mb-3">{safeDescription(template)}</h4>
        <Link to={`/templates/${template.slug}/preview${previewQueryParams(queryParams)}`}>
          <div className="btn btn-secondary align-center h-36 py-1 w-100 mb-2">
            <span>Preview decision</span>
          </div>
        </Link>
        {
          isDecisionSet
            ? <TemplateTileSelectButton
              template={template}
              set_slug={id}
              section_slug={section_slug}
              selectedTemplates={selectedTemplates}
              setSelectedTemplates={setSelectedTemplates}
              showEnterCopiesNumberModal={showEnterCopiesNumberModal}
              setShowEnterCopiesNumberModal={setShowEnterCopiesNumberModal}
              handleSelectDecision={handleSelectDecision}
              selectedObj={selectedObj}
            />
            : <AddButtonWrapper onClick={handleOnClick} addClass="mb-2">
              <i className="fas fa-plus fa-lg me-2"/>
              <span>Create decision</span>
            </AddButtonWrapper>
        }
      </div>
    </div>
  </div>
}
const mapStateToProps = ({decision_set}) => ({decision_set});
const mapDispatchToProps = (dispatch) => ({
  openModal: (data) => dispatch(openModal(data)),
  openSideBar: () => {
    dispatch(setSetDecisionsSidebarOpen(true))
  },
  startNewDecision: (data = {}, callback) => dispatch(startNewDecision(data, callback)),
  resetDecisionSetData: () => dispatch(resetDecisionSetData()),
  addSetInvites: (invites, callback) => {
    dispatch(addSetInvites(invites, callback))
  },
  loadAddedDecision: (slug) => dispatch(loadAddedDecision(slug))
});
export default connect(mapStateToProps, mapDispatchToProps)(TemplateTile);
