import React, { useCallback } from "react";
import BubbleTooltip from "../../../bubble_tooltip/BubbleTooltip";
import { safeDescription } from "../../../helpers/decision_helpers";
import { ButtonText, CollaboratorsImages, LinkWrapper } from "./DecisionTile";
import Decision from "../../../models/decision";
import { isMobile } from "react-device-detect";
import debounce from "lodash.debounce";

const DecisionTileContent = ({ decision, children, isSearch, isDSightHistory }) => (
  <div className={`homepage-list-col load-decision-slug-${decision.slug}`}>
    <div className="d-flex align-items-center list-col-content bg-white p-2">
      <div className="text-start overflow-hidden lh-sm">
        <div className="text-nowrap text-ellipsis fw-bolder">
          {safeDescription(decision)}
        </div>
        <div className="text-muted text-nowrap text-ellipsis">
          <ButtonText decision={decision} isSearchSection={isSearch} isDSightHistory={isDSightHistory} />
        </div>
      </div>
      <div className="ps-2 ms-auto">
        {children}
      </div>
    </div>
  </div>
);

const DecisionTile = ({
  decision,
  isDecisionSet,
  hasAccess,
  hideCollaborators,
  isSearch,
  isDSightHistory,
  isInDecisionSet,
  isSearchTab = false,
  selected,
  showChildren,
  setShowChildren,
  children
}) => {
  const decisionObj = new Decision(decision);
  const collaborators = decisionObj.collaborators;

  const debouncedSetShowChildren = debounce(setShowChildren, 100)

  const mouseLeaveHandler = () => selected || isMobile || debouncedSetShowChildren(false);

  return (
    <div
      className="col-12 mb-2"
      onMouseEnter={() => !isSearchTab && debouncedSetShowChildren(true)}
      onMouseLeave={mouseLeaveHandler}
    >
      {showChildren ? (
        <DecisionTileContent decision={decision} isSearch={isSearch} isDSightHistory={isDSightHistory}>
          {children}
        </DecisionTileContent>
      ) : (
        <LinkWrapper isSearchSection={isSearch} decision={decision} hasAccess={hasAccess} isInDecisionSet={isInDecisionSet}>
          <DecisionTileContent decision={decision} isSearch={isSearch} isDSightHistory={isDSightHistory}>
            <div className="d-flex ps-2 ms-auto">
              <BubbleTooltip
                object_hash={decision}
                decision_set_name={decision.decision_set_name}
                isDecisionSet={isDecisionSet}
                inListLine={true}
              />
              <div className={`collaborators text-end`} hidden={hideCollaborators}>
                <CollaboratorsImages object={decision} collaborators={collaborators} />
              </div>
            </div>
          </DecisionTileContent>
        </LinkWrapper>
      )}
    </div>
  );
};

export default DecisionTile;