import React, {useState} from "react";
import { isStoreLoading } from "../helpers/callbacks_helpers";
import { Loader } from "../common/Loader";
import { setDecisionOrderSidebarOpen, setSetDecisionsSidebarOpen } from "../store/sidebar/actions";
import { loadTemplateSet, removeTemplateSet, updateTemplateSet, } from "../store/template_set/actions";
import { resetTemplateSetData, updateTemplateSetData } from "../store/template_set/common_actions";
import { resetTemplate } from "../store/template/common_actions";
import { resetTree } from "../store/tree/common_actions";
import { forceReloadHomepageTemplates } from "../store/homepage/actions";
import { closeModal } from "../store/modals/actions";
import { connect } from "react-redux";
import { checkLoadingTemplateSet } from "../template_set_view";
import { Wrapper } from "../template_set_view";
import TemplatesTab from "../homepage/tabs/TemplatesTab";
import { resetPlaybookNotes } from "../store/playbook_notes/actions";

const TemplateSetAddTemplateView = ({ template_set, ...opts }) => {
  checkLoadingTemplateSet({ ...opts, template_set })
  const [selectedTemplates, setSelectedTemplates] = useState({});

  return <Wrapper {...opts} {...{ template_set, selectedTemplates }} >
    {
      isStoreLoading(template_set) ?
        <Loader /> :
        <div className="w-100 wrapper-container width-fit-content">
          <div className="home container mt-3 mx-auto">
            <h1>{template_set.name}</h1>
            <TemplatesTab {...{isTemplateSet: true, selectedTemplates, setSelectedTemplates, isAddTemplate: true}} />
          </div>
        </div>
    }
  </Wrapper>
}
const mapStateToProps = ({ home, template_set, modal, template }) => ({ home, template_set, modal, template });
const mapDispatchToProps = (dispatch) => ({
  closeDecisionOrderSideBar: () => dispatch(setDecisionOrderSidebarOpen(false)),
  loadTemplateSet: () => dispatch(loadTemplateSet()),
  updateTemplateSet: (value, callback) => { dispatch(updateTemplateSet(value, callback)) },
  updateTemplateSetData: (data) => { dispatch(updateTemplateSetData(data)) },
  resetTemplate: () => dispatch(resetTemplate()),
  resetTree: () => dispatch(resetTree()),
  resetPlaybookNotes: () => dispatch(resetPlaybookNotes()),
  resetTemplateSet: () => dispatch(resetTemplateSetData()),
  removeTemplateSet: (callback) => dispatch(removeTemplateSet(callback)),
  forceReloadHomepageTemplates: () => dispatch(forceReloadHomepageTemplates()),
  setSetDecisionsSidebarOpen: (value) => { dispatch(setSetDecisionsSidebarOpen(value)) },
  closeModal: () => { dispatch(closeModal()) }
});
export default connect(mapStateToProps, mapDispatchToProps)(TemplateSetAddTemplateView);
